import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";
import { LayoutContainerBaseProps } from "./layout.model";
import { GTMIdTypes } from "@/utils/ga-utils";

interface HorizontalContainerProps extends LayoutContainerBaseProps {
  children: ReactNode;
  /**
   * dataLabel is for Google Tag Manager
   */
  dataLabel?: string;
  /**
   * Id for Google Tag Manager
   */
  idForGTM?: GTMIdTypes;
  noWrap?: boolean; // prevent flex row from wrapping
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `horizontal-container__${property}--${value}`;
  return className;
};

export const HorizontalContainer = ({
  children,
  dataLabel,
  height,
  horizontalPlacement,
  idForGTM,
  noWrap,
  verticalPlacement,
  width
}: HorizontalContainerProps) => {
  const cssAlignClass = cssClasses("vertical-placement", verticalPlacement);
  const cssHeightClass = cssClasses("height", height);
  const cssJustifyClass = cssClasses("horizontal-placement", horizontalPlacement);
  const cssWidthClass = cssClasses("width", width);

  return (
    <div
      className={classNames(
        "horizontal-container",
        cssAlignClass,
        cssHeightClass,
        cssJustifyClass,
        cssWidthClass,
        noWrap && "horizontal-container--no-wrap"
      )}
      data-label={dataLabel}
      id={idForGTM}
    >
      {children}
    </div>
  );
};
